@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/pagination';

* {
  word-wrap: break-word;
  scroll-behavior: smooth;
}

ol,
ul,
menu {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 1.5em;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

li::marker {
  font-size: 0.7em;
}

/* Video.js css start */
[class*='vjs'] {
  font-family: VideoJS !important;
}

.video-js .vjs-time-divider,
.video-js .vjs-current-time,
.video-js .vjs-duration {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: 50%;
}

.video-js .vjs-time-divider {
  padding: 0px !important;
  min-width: 1em !important;
}

.video-js .vjs-current-time {
  padding-right: 5px !important;
}

.video-js .vjs-duration {
  padding-left: 5px !important;
}

.vjs-current-time-display,
.vjs-duration-display {
  font-family: 'Inter', sans-serif !important;
}

.vjs-control-bar {
  font-size: 1em;
}

.video-js[data-vjs-player='true'] {
  background-color: transparent !important;
  font-size: 1em;
}

.video-js .vjs-remaining-time {
  display: none;
}

.video-js .vjs-picture-in-picture-control {
  display: none !important;
}
/* Video.js css end */

*:not(i) {
  font-family: 'Inter', sans-serif !important;
}

/* hide arrow on number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important ;
  margin: 0;
}

#filter-bar::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 16px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: none !important;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

@layer components {
  .btn-primary {
    @apply rounded-md border-0 bg-slate-100 p-2 px-6;
  }

  .btn {
    @apply w-full rounded-[10px] py-3 text-center text-base font-medium text-white;
  }
}

.blue-gradient {
  background: linear-gradient(270deg, #2691bd 0%, #7acced 100%);
}

.text-blue-gradient {
  background: linear-gradient(270deg, #2691bd 0%, #7acced 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-primary {
  color: #2691bd;
}

.bg-primary__gradient {
  background: linear-gradient(270deg, #2691bd 0%, #7acced 100%);
}

.checkbox-group {
  display: block;
}

.checkbox-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-group label {
  position: relative;
  cursor: pointer;
  margin: auto;
  font-size: 16px;
  line-height: 30px;
}

.checkbox-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #231f2070;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 5px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin: 5px;
  margin-left: 0px;
  margin-right: 12px;
  border-radius: 20%;
}

.checkbox-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 11px;
  border: solid #231f20;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.h-screen-app {
  min-height: calc(100dvh - 5rem) !important;
}

.cus-trans {
  transition: 0s ease-in;
}

.bg-blue-gradient-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(270deg, #2691bd 0%, #7acced 100%);
}

.image-holder__container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.image-holder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: white;
}

/* styles for swipe module */
.swiper-slide:not(.not-custom) {
  opacity: 0.5;
  scale: 85%;
}
.swiper-slide-active:not(.not-custom) {
  opacity: 1;
  width: 50px;
  scale: 110%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.time-picker .swiper-slide-active {
  margin-top: 6px;
  margin-bottom: 10px;
}

/* custom css for animation */
@keyframes celebSuggestInAnimation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(-0.75rem, -1.25rem);
    opacity: 1;
  }
}

@keyframes celebSuggestOutAnimation {
  40% {
    transform: translate(-0.6rem, -1rem);
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes skeletonCelebSuggestIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes skeletonCelebSuggestOut {
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}

#pdf-renderer::-webkit-scrollbar {
  width: 7px;
  opacity: 1;
}

#pdf-renderer::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 16px;
}

#pdf-renderer::-webkit-scrollbar-track {
  opacity: 0;
}

/* #proxy-renderer {
  background-color: #2523245a;
} */

/* @media only screen and (max-width: 700px) {
  .grid-autofit-col {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 25px;
  }
}

@media only screen and (min-width: 700px) {
  .grid-autofit-col {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .grid-autofit-col {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .grid-autofit-col {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 25px;
  }
} */

.grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* gap: 25px; */
}
.invitation-grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(50% - 0.5rem), calc(50% - 0.5rem))
  );
  column-gap: 1rem; /* 16px */
  row-gap: 0.5rem;
  /* gap: 25px; */
}
.file-grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  /* gap: 25px; */
}
.course-grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* gap: 25px; */
}
.lesson-grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  /* gap: 25px; */
}

.question-table-grid-autofit-col {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
  /* gap: 25px; */
}
.student-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.student-table th,
.student-table td {
  border-top: 2px solid #bfbfbf4d !important;
  border-bottom: 2px solid #bfbfbf4d !important;
}
.student-table th:first-child,
.student-table td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 2px solid #bfbfbf4d !important;
}
.student-table th:last-child,
.student-table td:last-child {
  border-radius: 0 4px 4px 0;
  border-right: 2px solid #bfbfbf4d !important;
}

/* comment custom css */

.reply-line {
  width: 10px;
  height: calc(50%);
  border-bottom-left-radius: 10px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: #1a1a1a;
}

.root-comment-line {
  width: 1px;
  height: calc(100%);
  background-color: #1a1a1a;
}

.background-default {
  background: linear-gradient(90deg, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%);
}

/* custom tinymce */

.tox:not([dir='rtl']) {
  border: none;
}
.mce-content-body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiPickersCalendarHeader-label {
  text-transform: capitalize;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

input:not(:placeholder-shown) {
  color: #1a1a1a;
}
